import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Job, Page, PageJobs, Project } from 'src/app/shared/interfaces/model';
import { User } from 'src/app/shared/interfaces/user.model';
import { ArraysService } from 'src/app/shared/services/arrays-service/title-service';
import { JobsService } from 'src/app/shared/services/jobs.service';
import { PagesService } from 'src/app/shared/services/pages.service';
import { ProjectService } from 'src/app/shared/services/project.service';
import { SenderService } from 'src/app/shared/services/sender.service';
import { UserService } from 'src/app/shared/services/user.service';
import { PrivacyDialogComponent } from '../../privacy-dialog/privacy-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-public-job',
  templateUrl: './public-job.component.html',
  styleUrls: ['./public-job.component.scss']
})
export class PublicJobComponent implements OnInit {

  object: any;
  firstFormGroup = this._formBuilder.group({
    start: new UntypedFormControl,
    end: new UntypedFormControl,
  });
  job: Job;
  project: Project;
  user: User;
  page: Page;
  public text;
  public iconOffer;
  public textOffer;
  minDate = new Date();
  selectedDate = new Date();
  startDateLimited = new Date();
  currentYear = new Date().getFullYear();
  currentDate = new Date().getDate();
  currentMonth = new Date().getMonth();
  endDateLimited = new Date(this.currentYear, this.currentMonth, this.currentDate + 10);

  constructor(private _formBuilder: UntypedFormBuilder,
    private jobService: JobsService,
    private projectService: ProjectService,
    public router: Router,
    private route: ActivatedRoute,
    private senderService: SenderService,
    private pageService: PagesService,
    private userService: UserService,
    private titleService: ArraysService,
    private dialog: MatDialog,
    private userAuth: AuthService
  ) { }

  ngOnInit(): void {
    this.titleService.getOfferArrayProject();
    this.titleService.getPrivacyArray();

    this.object = JSON.parse(this.route.snapshot.paramMap.get('my_object'));

    if (this.router.url.match('jobs')) {
      this.jobService.getJob(localStorage.getItem("jobID")).subscribe((job) => {
        this.job = job;
      });
    } else {
      this.projectService.getProject(localStorage.getItem("projectID")).subscribe((project: Project) => {
        this.project = project;
      });
    }



    this.senderService.changeDataSource.subscribe((response: any) => {
      console.log(response, "ress s")
      if (response.id === "user") {
        this.user = response.data;
        if (localStorage.getItem('projectID')) {
          this.projectService.getProject(localStorage.getItem("projectID")).subscribe((project: Project) => {
            this.project = project;
            this.privacyTextOffer = this.project;
            this.privacyIconOffer = this.project;
          });
        } else if (localStorage.getItem('jobID')) {
          this.jobService.getJob(localStorage.getItem("jobID")).subscribe((job) => {
            this.job = job;
          });
        }

      } else if (response.id === "page") {
        this.page = response.data;
        if (localStorage.getItem('projectID')) {
          this.projectService.getProject(localStorage.getItem("projectID")).subscribe((project: Project) => {
            this.project = project;
            this.privacyTextOffer = this.project;
            this.privacyIconOffer = this.project;

          });
        } else if (localStorage.getItem('jobID')) {
          this.jobService.getJob(localStorage.getItem("jobID")).subscribe((job) => {
            this.job = job;
          });
        }

      }
    })
  }




  saveData() {
    this.jobService.getJob(localStorage.getItem("jobID")).subscribe((job) => {
      this.job = job;
      this.job.public = {
        startDate: this.firstFormGroup.get("start").value,
        endDate: this.firstFormGroup.get("end").value,
      };

      if (this.page) {


        const job: PageJobs = {
          jobId: this.job._id,
          public: this.job.public,
          timestamp: new Date(),
          c: this.job.c
        }



        this.jobService.getJobListPage(this.page._id).subscribe((res: any) => {
          const job = res[0].jobs.find(r => r.jobID === this.job._id);
          job.public = this.job.public;
          if (res[0].jobs.length > 1) {
            this.arraymove(res[0].jobs, job, 0);
          }
        //  this.arraymove(res[0].jobs, job, 0);
          this.jobService.updateJobList(res[0]._id, res[0]);

          this.jobService.updateJob(this.job._id, this.job);
        })


        this.router.navigateByUrl(`${this.page.pageUrl}${'/published/jobs/'}${this.job._id}`);


      } else if (this.user) {
        /*  const findJob = this.user.jobs.data.findIndex(t => t.jobId === this.job._id);
          this.arraymove(this.user.jobs.data, findJob, 0);

          this.user.jobs.data[findJob].public = this.job.public;
          if (!!this.user.jobs.data[findJob].public) {
            this.user.jobs.data[findJob].public = {};
          }
          this.user.jobs.data[findJob].public = this.job.public;

          this.user.jobs.data.splice(findJob, 1); */

        const job: PageJobs = {
          jobId: this.job._id,
          public: this.job.public,
          timestamp: new Date(),
          c: this.job.c
        }

        this.userService.getJobList(this.userAuth.userID).subscribe(res => {
          const projectData = res[0].jobs.find(r => r.jobID === this.job._id);
          projectData.public = this.job.public;
          this.jobService.updateJobList(res[0]._id, res[0]);
        })

        //  this.user.jobs.data.unshift(job)
        // this.userService.updateUser(this.user._id, this.user);
        this.jobService.updateJob(this.job._id, this.job);
        this.router.navigate([this.user.userUrl + '/publicated/jobs/'], { queryParams: { id: this.job._id } });
     //   this.router.navigateByUrl(`${this.user.userUrl}${'/publicated/jobs/'}${this.job._id}`);
      }
    });
  }
  publicProject() {
    this.projectService.getProject(localStorage.getItem("projectID")).subscribe((project: Project) => {
      this.project = project;
      this.project.public = {
        startDate: this.firstFormGroup.get("start").value,
        endDate: this.firstFormGroup.get("end").value,
      };



      if (this.page) {
       // this.pageService.updatePage(this.page._id, this.page);

        this.projectService.getProjectListPage(this.page._id).subscribe((res) => {
          const job = res[0].projects.find(r => r.projectID === this.project._id);
          const jobIndex =  res[0].projects.findIndex(r => r.projectID === this.project._id);
          job.public = this.project.public;
          if (res[0].projects.length > 1 && jobIndex !== 0) {
            this.arraymove(res[0].projects, jobIndex, 0);
          }

         this.projectService.updateProjectList(res[0]._id, res[0]);

        this.projectService.updateProject(this.project._id, this.project);

        })
        this.router.navigate([this.page.pageUrl + '/published/projects/', { id: this.project._id }]);

      } else if (this.user) {
        this.project.public = {
          startDate: this.firstFormGroup.get("start").value,
          endDate: this.firstFormGroup.get("end").value,
        };
        this.userService.getProjectList(this.userAuth.userID).subscribe(res => {
          const projectData = res[0].projects.find(r => r.projectID === project._id);
          projectData.public = this.project.public;
          this.projectService.updateProjectList(res[0]._id, res[0]);
        })



        this.projectService.updateProject(this.project._id, this.project);
        localStorage.setItem("userSearchID", this.user._id);
        this.router.navigate([this.user.userUrl + '/publicated/projects/', { projectID: this.project._id  }]);

    //   this.router.navigateByUrl(`${this.user.userUrl}${'/publicated/projects/'}${this.project._id}`);
      }
    })



  }
  arraymove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }


  openPrivacyOffer(data) {
    this.dialog.open(PrivacyDialogComponent, {
      data: data
    }).afterClosed().subscribe(t => {
      if (t) {
        this.project.apply.s = t;
        this.privacyIconOffer = this.project;
        this.privacyTextOffer = this.project;
        this.project.apply.timestamp = new Date();
        this.projectService.updateProject(this.project._id, this.project);
      }
    })
  }

  public set privacyTextOffer(project: Project) {
    this.textOffer = this.titleService.offerArrayProject.find(t => t.id === project.apply.s).text;
  }
  public get privacyTextOffer() {
    return this.textOffer;
  }

  public set privacyIconOffer(project: Project) {
    this.iconOffer = this.titleService.offerArrayProject.find(t => t.id === project.apply.s).icon;
  }

  public get privacyIconOffer() {
    return this.iconOffer;
  }
}
