import { CdkDragDrop, copyArrayItem, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { FilterState, IssueStatus, IssueStatusDisplay, JIssue } from 'src/app/shared/interfaces/jira.model';
import { Job, Project, UserJobs } from 'src/app/shared/interfaces/model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { JobsService } from 'src/app/shared/services/jobs.service';
import { ProjectService } from 'src/app/shared/services/project.service';
import { SenderService } from 'src/app/shared/services/sender.service';
import { IssueUtil } from 'src/app/shared/utils/issue';
import _ from 'lodash'
import { Store } from '@ngrx/store';
import { Console } from 'console';
import { share, take } from 'rxjs/operators';
import { ChangeDetectionService } from 'src/app/shared/services/change-detection.service';

@Component({
  selector: 'app-board-dnd-list',
  templateUrl: './board-dnd-list.component.html',
  styleUrls: ['./board-dnd-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BoardDndListComponent implements OnInit, OnChanges {
  @Input() status: IssueStatus;
  @Input() currentUserId: string;
  @Input() issues$: Observable<JIssue[]>;
  public job: Job;
  public project: Project
  public jobManage;
  public isProject: boolean = false;
  IssueStatusDisplay = IssueStatusDisplay;
  get issuesCount(): number {
    return this.job?.manage_.length;
  }
  constructor(private _projectService: ProjectService,
    private jobService: JobsService,
    private authService: AuthService,
    private sendService: SenderService,
    private changeDetectionService: ChangeDetectionService,
    private cd: ChangeDetectorRef) { }
  ngOnChanges(changes: SimpleChanges): void {
    this.cd.detectChanges();

  }

  ngOnInit(): void {

    this.sendService.manageProjectID.pipe(take(1)).subscribe(res => {
      if (res || res?.isProject) {
        this.isProject = true;
        this.changeDetectionService.emmitChanges.subscribe(t => {
          if (!!t) {
            this._projectService.getProject(res.ID).subscribe(response => {
              this.project = response;
              this.jobManage = _.cloneDeep(this.project.manage_);
              this.project.manage_ = this.project.manage_.filter((x) => x.status === this.status);
            })
          }
          this._projectService.getProject(res.ID).subscribe((response) => {
            this.project = response;
            this.jobManage = _.cloneDeep(this.project.manage_);
            this.project.manage_ = this.project.manage_.filter((x) => x.status === this.status);

          })

        });
      } else {
        this.sendService.dataManageJobID$.subscribe((job: any) => {
          this.jobService.getJob(job.ID).subscribe((res) => {
            this.job = res;
            this.jobManage = _.cloneDeep(this.job.manage_);
            this.job.manage_ = this.job.manage_.filter((x) => x.status === this.status);
          });
        });
      }

    })
  }
  drop(event: CdkDragDrop<JIssue[]>) {
    const newIssue: JIssue = { ...event.item.data };
    const newIssues = [...event.container.data];

    if (event.previousContainer === event.container) {
      moveItemInArray(newIssues, event.previousIndex, event.currentIndex);
      let jobFilters = this.jobManage.filter(t => t.s !== newIssue.status);
      let jobWithoutFilter = newIssues.filter(t => t.status === newIssue.status);
      jobWithoutFilter.unshift(...jobFilters)

      if (this.isProject) {
        this.project.manage_ = [];
        this.project.manage_ = jobWithoutFilter;
        //  this.jobService.updateJob(this.job._id, this.job);
        this.project.manage_ = newIssues;
      } else {
        this.job.manage_ = [];
        this.job.manage_ = jobWithoutFilter;
        this.jobService.updateJob(this.job._id, this.job);
        this.job.manage_ = newIssues;
      }



    } else {
      transferArrayItem(
        event.previousContainer.data,
        newIssues,
        event.previousIndex,
        event.currentIndex
      );

      newIssue.status = event.container.id as IssueStatus;
      const newIssueStatus = newIssue.status;

      this.updateListPosition(newIssues, newIssue, event.previousIndex, newIssueStatus);
      if (this.isProject) {
        this.project.manage_ = newIssues;
      } else {
        this.job.manage_ = newIssues;

      }
      // console.log(this.job.manage_[event.currentIndex]);
      //  this.jobService.updateJob(this.job._id, this.job)
      //  this._projectService.updateIssue(newIssue);
    }

  }



  filterIssues(issues: JIssue[], filter?: FilterState): JIssue[] {
    const { onlyMyIssue, ignoreResolved, searchTerm } = filter;
    return issues.filter((issue) => {
      const isMatchTerm = searchTerm ? IssueUtil.searchString(issue.title, searchTerm) : true;




      const isIgnoreResolved = ignoreResolved ? issue.status !== IssueStatus.DONE : true;

      return isMatchTerm && isIgnoreResolved;
    });
  }

  isDateWithinThreeDaysFromNow(date: string) {
    const now = new Date();
    const inputDate = new Date(date);
  }

  private updateListPosition(newList: JIssue[], issueChanged?: any, olderIndex?: any, status?: string) {
    issueChanged.updatedAt = new Date();
    issueChanged.status = status;
    const index = this.jobManage.findIndex(t => t.id === issueChanged.id);
    this.jobManage[index] = issueChanged;

    if (this.isProject) {
      this.project.manage_ = [];
      this.project.manage_ = this.jobManage;
      this._projectService.updateProject(this.project._id, this.project)

    } else {
      this.job.manage_ = [];
      this.job.manage_ = this.jobManage;
      this.jobService.updateJob(this.job._id, this.job);
    }
  }

}
