import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { shareReplay } from "rxjs/operators";
import { Page, Image, Roles, UserPage } from "src/app/shared/interfaces/model";
import { ImageService } from "src/app/shared/services/image.service";
import { PagesService } from "src/app/shared/services/pages.service";
import { SenderService } from "src/app/shared/services/sender.service";
import { UserService } from "src/app/shared/services/user.service";
import { AuthService } from "../../shared/services/auth.service";
import { Status } from "../../shared/utils/page-helper.service";
import _ from 'lodash'
import { BranchesService } from "src/app/shared/services/branches.service";
import { CategoryService } from "src/app/shared/services/category.service";
import { O } from "@angular/cdk/keycodes";
import { User } from "src/app/shared/interfaces/user.model";


@Component({
  selector: "app-pages-list",
  templateUrl: "./pages-list.component.html",
  styleUrls: ["./pages-list.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class PagesListComponent implements OnInit {
  public showWeb = false;
  public hasPhoto: boolean;
  public image;


  @Input() page: Page;
  @Input() details;
  @Input() sidebar;
  @Input() user;
  @Input() sidebarPage;
  @Input() userPage: UserPage;
  @Input() pageDetails: Page | any;
  @Input() pageProjects: Page;
  @Input() projects;
  @Input() fromJobs;
  @Input() projectPageID;
  @Output() selectedIndex: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedPage: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedPageIndex: EventEmitter<any> = new EventEmitter<any>();
  @Output() rejectPageIndex: EventEmitter<any> = new EventEmitter<any>();
  public pageID = "";
  public checkStatusPage;
  public branchTitle = "";
  selectedID;
  public pending = false;
  subCategoryTitle = "";
  public filterPage;
  public filterPageJobs;
  constructor(
    public router: Router,
    public pageService: PagesService,
    public imageService: ImageService,
    public sendPageData: SenderService,
    public userService: UserService,
    private authService: AuthService,
    private branchesService: BranchesService,
    public categoryService: CategoryService,
  ) {

  }
  ngOnInit(): void {
    this.branchesService.getBranches();
    this.categoryService.getCategories();

    this.checkStatusPage = this.user?.userUser?.pages.find(t => t.pageId === this.pageDetails._id);
    /*  if (this.projects) {
        if (!!this.userPage.pageId) {
          this.pageService.getPage(this.userPage?.pageId).subscribe(data => {
            this.page = data;
            this.filterPage = this.page?.roles?.Projects.find(t => t.userID === this.authService.userID);
            this.getBranch(this.page?.branches);
            this.getSubCategoryTitle(this.page?.categories?.name);

          })
        }

      } else {
        if (!!this.userPage) {
          this.pageService.getPage(this.userPage?.pageId).subscribe(data => {
            this.page = data;
            console.log(this.page, "page")
           this.filterPageJobs  = this.page?.roles?.Jobs.find(t => t.userID === this.authService.userID);
            this.getBranch(this.page?.branches);
            this.getSubCategoryTitle(this.page?.categories?.name);

          })
        }

      } */

  }

  goToPageProject(page: Page) {
    localStorage.setItem("pageID", page._id)
    this.selectedIndex.emit(this.projectPageID);
    this.sendPageData.sendDataForProject(page);
    this.selectedPage.emit(this.userPage);
  }

  goToPageProjectDetails(page: Page) {
    this.pageService.getPage(page._id).subscribe(res => {

      localStorage.setItem("slcPageProjectID", page._id)
      this.selectedIndex.emit(this.pageProjects._id);
      this.sendPageData.sendDataForProject(res);
      this.selectedPage.emit(page);

    })
  }

  selectPage(page: Page) {
    this.selectedPageIndex.emit(this.projectPageID);
    this.sendPageData.sendDataForProject(page);
    this.selectedPage.emit(this.userPage);
  }

  goToWeb(id) {
    this.showWeb = true;
    /* if (this.userPage.status === Status.Pending) {
       return;
     } */
    this.pageService.getPage(this.pageDetails._id).subscribe(data => {
      this.page = data.page;
      if (this.page.pageUrl) {
        this.router.navigate(['', this.page.pageUrl]);
      } else {
        this.router.navigate(['', this.page._id]);

      }
    })
    this.pageID = id;
    localStorage.setItem("pageID", id)
    this.pageService.changeMessage(id);
    this.sendPageData.send(this.page);
  }
  getBranch(id) {
    this.branchTitle = this.branchesService.branches.find(t => t.id === id);
  }
  getSubCategoryTitle(id) {
    const t = this.categoryService.categories.find(t => t.id === id);
    const tt = this.page?.categories.subCategory.find(t => t.name);
    this.subCategoryTitle = t?.subCategory.find(rtt => rtt.id === tt.name).name;
  }


  acceptPage(page: Page) {
    this.userService.getUserEM(this.authService.userID).pipe(shareReplay(1)).subscribe((res: any) => {
      res = res.user;
      res.pages.find(r => r.pageId === page._id).status = Status.Added;
      const userData = {
        userID: this.authService.userID,
        timestamp: new Date()
      };
      const userAcceptPage = {
        pageId: page._id,
        timestamp: new Date()
      };

      // res.following.data.unshift(userAcceptPage);
      this.checkStatusPage.status = Status.Added;
      this.pageService.updatePage(page._id, page);
      this.userService.updateuUser(res._id, res);
    });
  }

  rejectPage(page: Page) {
    this.userService.getUserEM(this.authService.userID).pipe(shareReplay(1)).subscribe((res: any) => {


      this.pageService.getPage(page._id).subscribe(t => {

        console.log(t.roles["Owner"])

       const indexOwner = t.roles.Owner.findIndex(rt => rt.userID === res.user.xUserID);
        if (indexOwner !== -1) {
          t.roles.Owner.splice(index, 1);
        }
        const indexAdmin = t.roles.Admin.findIndex(rt => rt.userID === res.user.xUserID);
        if (indexAdmin !== -1) {
          t.roles.Admin.splice(index, 1);
        }

        const indexEditor = t.roles.Editor.findIndex(rt => rt.userID === res.user.xUserID);
        if (indexEditor !== -1) {
          t.roles.Editor.splice(index, 1);
        }

        const indexModerator = t.roles.Moderator.findIndex(rt => rt.userID === res.user.xUserID);
        if (indexModerator !== -1) {
          t.roles.Moderator.splice(index, 1);
        }

        const indexProjects = t.roles.Projects.findIndex(rt => rt.userID === res.user.xUserID);
        if (indexProjects !== -1) {
          t.roles.Projects.splice(index, 1);
        }

        const indexJobs = t.roles.Jobs.findIndex(rt => rt.userID === res.user.xUserID);
        if (indexJobs !== -1) {
          t.roles.Jobs.splice(index, 1);
        }

        const indexUser = t.roles.User.findIndex(rt => rt.userID === res.user.xUserID);
        if (indexUser !== -1) {
          t.roles.User.splice(index, 1);
        }

        const indexAnalyst = t.roles.Analyst.findIndex(rt => rt.userID === res.user.xUserID);
        if (indexAnalyst !== -1) {
          t.roles.Analyst.splice(index, 1);
        }
        const indexAdvertiser = t.roles.Advertiser.findIndex(rt => rt.userID === res.user.xUserID);
        if (indexAdvertiser !== -1) {
          t.roles.Advertiser.splice(index, 1);
        }

        console.log(t.roles, "tttt")

        this.pageService.updatePageAndRoles(page, t.roles).subscribe();
      })

      const index = res.user.pages.findIndex(t => t.pageId === page._id);
      if (index !== -1) {
        res.user.pages.splice(index, 1);
      }
      this.userService.updateuUser(res.user._id, res.user);
      this.rejectPageIndex.emit();
    });
  }
}
