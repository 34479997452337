import { Component, Inject, OnInit } from "@angular/core";
import { Job, Language, Model, Project } from "../../../../shared/interfaces/model";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
import { LanguagesService } from "../../../../shared/services/languages.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { WarningDialogComponent } from "src/app/sharedComponents/warning-dialog/warning-dialog.component";
import { UserService } from "src/app/shared/services/user.service";
import { User, UserUser } from "src/app/shared/interfaces/user.model";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { UntypedFormControl } from "@angular/forms";

@Component({
  selector: "app-language-dialog",
  templateUrl: "./language-dialog.component.html",
  styleUrls: ["./language-dialog.component.scss"]
})
export class LanguageDialogComponent implements OnInit {
  starCount = 5;
  empty = "";
  public confirmationDialog: MatDialogRef<WarningDialogComponent>
  public user: User;
  public name;
  languages = [];
  selectedLanguage;
  filteredOptions: Observable<any[]>;
  currentVersion;
  selectedValueLanguage;
  isNotTranslated: boolean = false;
  isNotSameVersion: boolean = false;
  showSecondaryLanguage: boolean = false;
  myControl = new UntypedFormControl('');
  myControlSecond = new UntypedFormControl('');
  languageControl = new UntypedFormControl('');
  selectedSecondLanguage;
  secondLanguageArray;
  public dataList$: Observable<any>;
  selectedCV: any;
  public uUser: UserUser;
  cv: any
  constructor(@Inject(MAT_DIALOG_DATA) public data: EditLanguage,
    private dialogRef: MatDialogRef<LanguageDialogComponent>,
    public authService: AuthService,
    public dialog: MatDialog,
    public languageService: LanguagesService,
    public http: HttpClient,
    public userService: UserService) { }

  ngOnInit(): void {
    if (this.data.model) {
      this.http.get<any>(`assets/languages/${this.data.model.language.primary}/language.json`).subscribe(data => {

        this.languages = data;
        this.languages = Object.keys(this.languages).map(key => ({ type: key, value: this.languages[key] }));
        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value || '')),
        );
      })
    } else {
      if (this.data.user) {
        this.http.get<any>(`assets/languages/${this.data.user.language.primary}/language.json`).subscribe(data => {

          this.languages = data;
          this.languages = Object.keys(this.languages).map(key => ({ type: key, value: this.languages[key] }));
          console.log(this.languages, "getLanguageName")
          this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value || '')),
          );
        })
      } else {
        this.userService
          .getUserContactData(this.authService.userID)
          .subscribe((res) => {
            this.user = res;
            this.http.get<any>(`assets/languages/${this.user?.language?.primary}/language.json`).subscribe(data => {

              this.languages = data;
              this.languages = Object.keys(this.languages).map(key => ({ type: key, value: this.languages[key] }));
              this.filteredOptions = this.myControl.valueChanges.pipe(
                startWith(''),
                map(value => this._filter(value || '')),
              );
            })
          });
      }

    }

    if (!this.data.projectLanguage && !this.data.jobLanguage && !this.data.editJobQualifications && !this.data.editProjectQualifications && !this.data.cvLanguage) {
      if (!this.data.edit) {
        this.data.language = {} as Language[];
      } else {
        this.dataList$ = this.userService.dataList;
        this.dataList$.subscribe((res: any) => {
          this.user = res.user;
          this.uUser = res.userUser;
          this.selectedCV = res.cv;
          this.cv = res.userUser.cv.data[this.data.indexOfLang];

          this.data.language = res.cv?.data[this.data.indexOfLang]?.skills[0].languages.data;
        });

      }
    } else if (this.data.projectLanguage) {
      this.data.project = new Project();
      this.data.project.language = [];
    } else if (this.data.jobLanguage) {
      this.data.job = new Job();
      this.data.job.language = [];
    } else if (this.data.editJobQualifications) {
      this.data.job = new Job();
      this.data.job.qualifications = [];
    } else if (this.data.editProjectQualifications) {
      this.data.project = new Project();
      this.data.project.qualifications = [];
    } else if (this.data.cvLanguage) {
      this.languageService.getPrimaryLanguages();
      this.currentVersion = localStorage.getItem("languageVersion");

      if (this.data.editCVData) {
        const version = this.languageService.primaryLanguages.find(t => t.lang === this.data?.cvData?.language?.primary)?.version;
        this.secondLanguageArray = this.languageService.primaryLanguages.filter(t => t.version === this.currentVersion);
        this.selectedValueLanguage = this.languageService.primaryLanguages.find(t => t.lang === this.data.cvData?.language?.primary);
        if (!!this.data?.cvData.language.secondary) {
          this.selectedSecondLanguage = this.languageService.primaryLanguages.find(t => t.lang === this.data?.cvData.language.secondary)
        }

        if (!!this.currentVersion && this.currentVersion !== version) {
          this.isNotSameVersion = true;

        } else if (!version) {
          this.isNotTranslated = true;

        }
      } else {
        this.data.cvData = new Model();
        this.secondLanguageArray = this.languageService.primaryLanguages.filter(t => t.version === this.currentVersion);
      }



    }
  }
  onRatingChanged(index, rating) {
    if (this.data.jobLanguageEdit) {
      this.data.job.language[index].rate = rating;
    } else {
      this.data.project.language[index].rate = rating;
    }
  }
  saveData() {
    if (this.authService.isLoggedIn) {
      if (this.data.projectLanguageEdit) {

        this.dialogRef.close({ isProject: true, project: this.data.project })
      } else {
        this.selectedCV.data[this.data.indexOfLang].skills[0].languages.data.unshift(...this.data.language);


        const data = this.selectedCV.data[this.data.indexOfLang].skills[0].languages.data.filter((el, i, a) => i === a.findIndex((findTest) => findTest.name === el.name))

        this.user.searchFilterSkills.searchLanguages.data = data;
        this.selectedCV.data[this.data.indexOfLang].skills[0].languages.data = data;

        this.userService.updateCV(this.cv.cvID, this.selectedCV);
        this.userService.updateUser(this.user._id, this.user);
        this.userService.updateuUser(this.uUser._id, this.uUser);

        this.dialogRef.close(this.selectedCV.data[this.data.indexOfLang].skills[0]);

      }



      if (this.user) {
        this.dialogRef.close(this.user.cv.data[0].skills[0]);
      }

      // this.dataService.updatePage(this.data.model._id, this.data.model);
    } else {
      this.dialogRef.close();
      this.confirmationDialog = this.dialog.open(WarningDialogComponent, {
        disableClose: false
      });

      this.confirmationDialog.afterClosed().subscribe(result => {
        if (result) {
          // do confirmation actions
        }
        this.confirmationDialog = null;
      });
    }
  }

  saveLanguageJob() {
    this.dialogRef.close({ isProject: false, job: this.data.job, isJob: true });
  }


  deleteLanguage(index) {
    if (!!this.data.projectLanguage) {
      this.data.project.language.splice(index, 1);
    } else {
      if (!!this.data.language && Object.keys(this.data.language).length) {
        this.data.language.splice(index, 1);
      } else {
        this.data.job.language.splice(index, 1);
      }
    }
  }



  addProjectLanguage(event) {
    this.data.project.language.unshift({ name: this.selectedLanguage, rate: 0 });
  }

  addJobLanguage(event) {
    this.data.job.language.unshift({ name: this.selectedLanguage, rate: 0 });
  }
  onJobRatingChanged(index, rating) {
    this.data.job.language[index].rate = rating * 20;
  }
  onProjectRatingChanged(index, rating) {
    this.data.project.language[index].rate = rating * 20;
  }

  addJobQualifications() {
    if (this.data.job?.qualifications?.length > 0) {
      this.data.job.qualifications.unshift({ name: this.name });
    } else {
      this.data.job.qualifications = [];
      this.data.job.qualifications.unshift({ name: this.name });
    }
    this.name = "";
  }

  addProjectQualifications() {
    if (this.data.project?.qualifications?.length > 0) {
      this.data.project.qualifications.unshift({ name: this.name });
    } else {
      this.data.project.qualifications = [];
      this.data.project.qualifications.unshift({ name: this.name });
    }
    this.name = "";
  }

  saveProjectData() {
    this.dialogRef.close({ isProject: true, project: this.data.project });
  }
  saveJobData() {
    this.dialogRef.close(this.data.job);
  }

  saveJobQualifications() {
    this.dialogRef.close(this.data.job);
  }
  saveProjectQualifications() {
    this.dialogRef.close(this.data.project);
  }

  deleteProjectQualifications(index) {
    this.data.project.qualifications.splice(index, 1);

  }

  test(event) {
    this.selectedLanguage = event.type;
  }
  addLanguage(event) {
    if (this.data.project) {
      this.data.project.language.unshift({ name: this.selectedLanguage, rate: 0 });
    } else {
      this.data.job.language.unshift({ name: this.selectedLanguage, rate: 0 });
    }
  }

  private _filter(value): any[] {

    const filterValue = value.toLowerCase();

    return this.languages.filter(option => option.value?.toLowerCase().includes(filterValue));
  }

  public getLanguageName(type) {
    return this.languages.find(t => t.type === type);
  }

  changeLanguageSecond(event) {

    console.log(this.data.cvData, " this.user.language.secondary");
    this.data.cvData.language.secondary = event.lang;
  }


  saveCVLanguage(data) {
    console.log(this.data, "data")
    this.dialogRef.close(this.data);
  }

  changeLanguage(event) {


    this.selectedLanguage = event;
    if (event.version === this.currentVersion) {
      setTimeout(() => {
        this.data.cvData.language = {};
        this.data.cvData.language.primary = this.selectedLanguage.lang;
        this.selectedValueLanguage = this.selectedLanguage;
        this.isNotSameVersion = false;
        this.isNotTranslated = false;
      }, 100)


    } if (!!event.version && event.version !== this.currentVersion) {
      setTimeout(() => {
        this.isNotSameVersion = true;
        if (!this.data.editCVData) {
          this.data.cvData.language = {};
          this.data.cvData.language.primary = this.selectedLanguage.lang;
        } else {
          this.data.cvData.language.primary = this.selectedLanguage.lang;
        }
        this.selectedValueLanguage = this.selectedLanguage;
        this.isNotTranslated = false;
      }, 100)

    } else if (!event.version) {
      setTimeout(() => {
        this.isNotTranslated = true;
        this.showSecondaryLanguage = true;
        this.isNotSameVersion = false;
        if (!this.data.editCVData) {
          this.data.cvData.language = {};
          this.data.cvData.language.primary = this.selectedLanguage.lang;
        } else {
          this.data.cvData.language.primary = this.selectedLanguage.lang;
        }
        this.selectedValueLanguage = this.selectedLanguage;
      }, 100)

    }


  }


}

export interface EditLanguage {
  language?: Language[];
  edit?: boolean;
  model?: Model;
  project?: Project;
  projectLanguage?: boolean;
  jobLanguage?: boolean;
  job?: Job;
  editJobQualifications?: boolean;
  editProjectQualifications?: boolean;
  cvLanguage?: boolean;
  cvData: Model;
  editCVData?: boolean;
  indexOfLang?: number;
  currency?: any;
  user?: any
  jobLanguageEdit?: boolean,
  projectLanguageEdit?: boolean;
  isProject?: boolean;
  isJob?: boolean;

}
