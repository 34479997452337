import { EditPCKnowledge } from './../../modals/editors/pc-knowledge-dialog/pc-knowledge-dialog.component';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Duration, EmploymentType, Status, Incomes, Remote } from 'src/app/shared/enums/enums-model';
import { JIssue, IssueStatus, IssueType, IssuePriority } from 'src/app/shared/interfaces/jira.model';
import { Job, Page, PageJobs, Project, UserJobs, UserProjects, UserSearch } from 'src/app/shared/interfaces/model';
import { User } from 'src/app/shared/interfaces/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ChangeDetectionService } from 'src/app/shared/services/change-detection.service';
import { CountryDataService } from 'src/app/shared/services/country-data.service';
import { JobsService } from 'src/app/shared/services/jobs.service';
import { PagesService } from 'src/app/shared/services/pages.service';
import { ProjectService } from 'src/app/shared/services/project.service';
import { SenderService } from 'src/app/shared/services/sender.service';
import { UserService } from 'src/app/shared/services/user.service';
import { EditDetailsData, EditJobsDialogComponent } from '../edit-jobs-dialog/edit-jobs-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { LanguageDialogComponent } from '../../modals/editors/language-dialog/language-dialog.component';
import { DriveLicenseDialogComponent } from '../../modals/editors/drive-license-dialog/drive-license-dialog.component';
import { QualificationsDialogComponent } from '../../modals/editors/qualifications-dialog/qualifications-dialog.component';
import { QualificationsDialogArrayComponent } from '../../modals/editors/qualifications-dialog-array/qualifications-dialog-array.component';

@Component({
  selector: 'app-job-item',
  templateUrl: './job-item.component.html',
  styleUrls: ['./job-item.component.scss']
})
export class JobItemComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() userJobs: UserJobs;
  @Input() myJobs: string;
  @Input() isTrue;
  @Input() isFalse: boolean = false;
  @Input() userUrl: string;
  @Input() savedJobs: boolean;
  @Input() ignoredJobs: boolean;
  @Input() userPublishedJobs;
  @Input() userJobSearchId;
  @Input() user: User;
  @Input() searchedJob: Job;
  @Input() appliedJobs: boolean;
  @Input() appliedJob: Job;
  @Input() hasUserProjects: boolean;
  @Input() userProjects: UserProjects
  @Input() isProjectSearch;
  @Input() isSavedProject;
  @Input() projectID: string
  @Input() isIgnoredProject;
  @Input() isProjectCompanySearch;
  @Input() pageID;
  @Input() appliedProjects: boolean = false;
  @Input() pageProjects;
  @Input() isPageJobs;
  @Input() selectedPageID: any;
  @Input() isPage: boolean = false;
  @Input() selectedPage: any;
  @Input() isSearchUser: boolean = false;
  @Input() isSearchPage: boolean = false;
  @Input() pageData: Page;
  @Input() userData: User | User[];
  @Input() pageObject: Page;
  @Input() projectSearch;
  @Input() appliedUserProjects;
  @Input() searchForJob: boolean = false;
  @Input() userSearch: boolean = false;
  @Input() searchUserID;
  @Input() userSearchFromJob: boolean = false;
  @Input() dataJob: Job;
  @Input() userJobsAd: boolean;
  @Input() userJob: boolean;
  @Input() pageJobs: PageJobs;
  @Input() isJob: boolean = false;
  @Input() jobID: any;
  @Input() myOwnJobs: boolean = false;
  @Input() isPublished: boolean = false;
  @Input() userEM: any;
  @Input() projectObjs: any
  @Input() projectList: any;
  public moreJobInfo: Job;
  public job: Job;
  public project: Project;
  public authUser: User;
  public userSearchObject: UserSearch;
  countryJsonObject;
  countryJsonArray;
  private isSaved;
  public isIgnored;
  public hasApplied;
  public hasAppliedPage;
  public projectApplied;
  public projectAppliedPage;
  isProjectCompanySearchData;
  public userForSearch: User;

  public jobSalary;
  public remote;
  public employmentTypeString;
  public jobDurationString;
  public currencyObject;
  public currencyUserSearchObject;
  public paymentID;
  public subscription: Subscription;
  public jIssue = new JIssue();
  userCreatedJob: User;
  page: Page;
  savedProject: Project;
  ignoredProject: Project;
  pageAppliedProject: Project;
  appliedProjectUser: Project;
  userAppliedProject: Project;
  loggedUser$: Observable<any>;
  loggedUser: any;
  public languages = [];
  public openMoreInfo = false;
  public employmentType = [
    { id: EmploymentType.EmployedFullTime, description: "employmentType.EmployedFullTime" },
    { id: EmploymentType.EmployedPartTime, description: "employmentType.EmployedPartTime" },
    { id: EmploymentType.Internship, description: "employmentType.Internship" },
    { id: EmploymentType.Owner, description: "employmentType.Owner" },
    { id: EmploymentType.BordMember, description: "employmentType.Boardmember" },
    { id: EmploymentType.Volunteer, description: "employmentType.Volunteer" },
    { id: EmploymentType.SelfEmployed, description: "employmentType.SelfEmployed" },
    { id: EmploymentType.Shareholder, description: "employmentType.Shareholder" },
    { id: EmploymentType.Official, description: "employmentType.Official" },
    { id: EmploymentType.Recruiter, description: "employmentType.Recruiter" },
    { id: EmploymentType.Freelancer, description: "employmentType.Freelancer" },
    { id: EmploymentType.Partner, description: "employmentType.Partner" }
  ];
  public remoteArray = [
    { id: Remote.onSite, description: "project.firstPage.onSite", percent: '0%' },
    { id: Remote.hybrid, description: "project.firstPage.hybrid", percent: '50%' },
    { id: Remote.remote, description: "project.firstPage.remote", percent: '100%' },
  ];


  public salaryArray = [
    { id: Incomes.hour, description: "incomes.hour" },
    { id: Incomes.month, description: "incomes.month" },
    { id: Incomes.year, description: "incomes.year" },
    { id: Incomes.total, description: "incomes.total" },
  ]
  public dataList$: Observable<any>;
  userObj: any;

  public jobDurationArray = [
    { id: Duration.unlimited, description: "job.fourthPage.duration.unlimited" },
    { id: Duration.temporary, description: "job.fourthPage.duration.temporary" },
  ]
  @Output() unsaveJobUsers: EventEmitter<any> = new EventEmitter<any>();
  @Output() unIgnoreJob: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeSavedProject: EventEmitter<any> = new EventEmitter<any>();
  @Output() unIgnoreProject: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeSavedProjectPage: EventEmitter<any> = new EventEmitter<any>();
  @Output() addToIgnoreProjectPage: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeFromIgnorePageProject: EventEmitter<any> = new EventEmitter<any>();

  @Output() ignoreArray: EventEmitter<any> = new EventEmitter<any>();





  constructor(
    public jobService: JobsService,
    public senderService: SenderService,
    public router: Router,
    private http: HttpClient,
    private userService: UserService,
    private authService: AuthService,
    private countryDataService: CountryDataService,
    private cd: ChangeDetectorRef,
    private pageService: PagesService,
    private projectService: ProjectService,
    private cdServ: ChangeDetectionService,
    private dialog: MatDialog,

  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    this.cd.detectChanges();
  }

  ngOnInit(): void {
    this.selectedPageID = localStorage.getItem("slcPageProjectID");

    if (!!this.user) {
      this.http
        .get<any>(
          `assets/languages/${this.user?.language.primary}/language.json`
        )
        .subscribe((response) => {
          this.languages = response;

          this.languages = Object.keys(this.languages).map((key) => ({
            type: key,
            value: this.languages[key],
          }));
        });

    }
    this.countryDataService.countryData$.pipe().subscribe(res => this.countryJsonArray = res);
    this.loggedUser$ = this.userService.userList;
    this.loggedUser$.subscribe(res => {
      this.user = res.user;
      this.appliedID = this.user?._id;
      this.loggedUser = res;

    })

    if (this.pageJobs) {
      if (this.pageJobs.jobID) {
        this.jobService.getJob(this.pageJobs.jobID).subscribe((job: Job) => {
          this.job = job;
        });
      } else if (this.pageJobs.projectID) {
        this.projectService.getProject(this.pageJobs.projectID).subscribe(res => {
          this.project = res;
          this.projectApplied = this.user?._id;
        })
      }
    }

    if (this.pageProjects) {
      if (this.pageProjects.jobID) {
        this.jobService.getJob(this.pageProjects.jobID).subscribe((job: Job) => {
          this.job = job;
        });
      } else if (this.pageProjects.projectID) {
        this.projectService.getProject(this.pageProjects.projectID).subscribe(res => {
          this.project = res;
          this.projectApplied = this.user?._id;
        })
      }
    }
    if (this.isPageJobs) {
      if (this.pageJobs.jobID) {
        this.jobService.getJob(this.pageJobs.jobID).subscribe((job: Job) => {
          this.job = job;
        });
      } else if (this.pageJobs.projectID) {
        this.projectService.getProject(this.pageJobs.projectID).subscribe(res => {
          this.project = res;
          this.projectApplied = this.user?._id;
        })
      }
    }


    if (this.userJobs) {
      if (this.userJobs.jobID && this.myOwnJobs) {
        this.jobService.getJob(this.userJobs.jobID).subscribe((job: Job) => {
          this.job = job;
        });
      } else if (this.userJobs.projectID) {
        this.projectService.getProject(this.userJobs.projectID).subscribe(res => {
          this.project = res;
          this.projectApplied = this.user?._id;
        })
      }
    }


    if (this.userSearch) {
      this.userService.getUserProfileForSearch(this.userJobs).subscribe(res => {
        this.userSearchObject = res;
        this.appliedID = this.user?._id;
        this.projectApplied = this.user?._id;
        this.getEmploymentTypeID(this.userSearchObject.cv[0].employmentType);
        this.getRemoteID(this.userSearchObject.searchLocation.remote);
        this.getCountryID(this.userSearchObject.cv[0].country);
        this.getCurrencyForUserSearch(this.userSearchObject.cv[0].country);
        this.getPaymentFormID(this.searchedJob.incomes.s);
        this.incomes(this.searchedJob.incomes.s, this.userSearchObject.cv[0]);
      })

    } else if (this.userPublishedJobs) {
      this.userService.getUserProfileForSearch(this.userJobs.jobID).subscribe(res => {
        this.userSearchObject = res;
        this.getEmploymentTypeID(this.userSearchObject.cv[0].employmentType);
        this.getRemoteID(this.userSearchObject.searchLocation.remote);
        this.getCountryID(this.userSearchObject.cv[0].country);
      })
    } else if (this.searchForJob) {
      this.jobService.getJob(this.userJobSearchId).subscribe(res => {
        this.job = res;
        if (this.job.pageID) {
          this.pageService.getPage(this.job.pageID).subscribe(t => {
            this.page = t;
          })
        } else {
          this.userService.getUserContactData(this.job.userID).subscribe(t => {
            this.userCreatedJob = t;
          })
        }
        // this.savedID = this.job._id;
        // this.ignoredID = this.job._id;
        this.appliedID = this.user._id;
        this.projectApplied = this.user?._id;

        this.getRemoteID(this.job.remote);
        this.getEmploymentTypeID(this.job.employmentType);
        this.getJoDurationID(this.job.duration);
        this.getCountryID(this.job.c);
        this.getCurrencyID(this.job.currency);
        this.getPaymentFormID(this.user.incomes.s);
        //  this.incomes(this.user.incomes.s, this.job);
      })
    } else if (this.savedJobs) {
      this.jobService.getUserJobs(this.userJobs.jobID).subscribe(res => {
        this.job = res;
        // this.savedID = this.job._id;
        //  this.ignoredID = this.job._id;
        this.appliedID = this.user._id;
        this.projectApplied = this.user?._id;
        this.getRemoteID(this.job.remote);
        this.getEmploymentTypeID(this.job.employmentType);
        this.getJoDurationID(this.job.duration);
        this.getCountryID(this.job.c);
        this.getCurrencyID(this.job.currency);
        this.getPaymentFormID(this.job.incomes.s);
      });
    } else if (this.userSearchFromJob) {
      this.userService.getUserOnce(this.searchUserID).subscribe(res => {
        this.userObj = res;
        this.userForSearch = res;
        console.log(this.userObj, "RESSS")

      });


    }


    else if (this.appliedJobs) {
      this.jobService.getJob(this.jobID).subscribe(res => {
        this.job = res;
        this.savedID = this.job._id;
        this.appliedID = this.user._id;
        this.projectApplied = this.user?._id;
        this.getRemoteID(this.job.remote);
        this.getEmploymentTypeID(this.job.employmentType);
        this.getJoDurationID(this.job.duration);
        this.getCountryID(this.job.c);
        this.getCurrencyID(this.job.currency);
        this.getPaymentFormID(this.job.incomes.s);
      });


    }
    else if (this.isSavedProject) {
      this.projectService.getProject(this.projectID).subscribe(res => {
        this.savedProject = res;
      })
    } else if (this.isIgnoredProject) {
      this.projectService.getProject(this.projectID).subscribe(res => {
        this.ignoredProject = res;
      })
    } else if (this.appliedUserProjects) {
      this.projectService.getProject(this.projectID).subscribe(res => {
        this.appliedProjectUser = res;
      })
    }
    else if (this.isTrue) {
      this.jobService.getJob(this.userJobs.jobID).subscribe(res => {
        this.job = res;
        this.getRemoteID(this.job.remote);
        this.getEmploymentTypeID(this.job.employmentType);
        this.getJoDurationID(this.job.duration);
        this.getCountryID(this.job.c);
        this.getCurrencyID(this.job.currency);
        this.getPaymentFormID(this.job.incomes.s);
      })
    } else if (this.hasUserProjects) {
      this.projectService.getProject(this.userProjects.projectID).subscribe((res: any) => {
        this.project = res;
      });
    } else if (this.userJob) {
      this.jobService.getJob(this.userJobs.jobID).subscribe((res: any) => {
        console.log(res, "res")
        this.job = res;
      });

    }

    else if (this.isProjectSearch) {
      this.projectService.getProject(this.userJobSearchId).subscribe((project: Project) => {
        this.project = project;
        this.appliedID = this.user._id;
        this.projectApplied = this.user?._id;
      });

    } else if (this.isProjectCompanySearch) {
      this.projectService.getProject(this.pageID).subscribe(res => {
        this.project = res;
        this.appliedProjectPage = this.user?._id;
        this.appliedIDPage = this.selectedPageID;
        this.hasApplied = false;
      })
    } else if (this.appliedProjects) {
      this.projectService.getProject(this.projectID).subscribe(res => {
        this.pageAppliedProject = res;
        this.appliedID = this.user._id;
        this.projectApplied = this.user?._id;

      })
    }

  }
  ngAfterViewInit() {
    if (this.appliedProjects) {
      this.projectService.getProject(this.projectID).subscribe(res => {
        this.pageAppliedProject = res;
      })
    }
    if (this.appliedUserProjects) {
      this.projectService.getProject(this.projectID).subscribe(res => {
        this.appliedProjectUser = res;
      })
    }
  }
  emmitID(jobID) {
    this.senderService.dataJobID(jobID);
    localStorage.setItem('jobID', jobID);
    localStorage.removeItem("projectID")
    this.router.navigate(["/jobs/public/", jobID]);
  }
  emmitIDTrue(jobID) {
    this.senderService.dataJobID(jobID);
    localStorage.setItem('jobID', jobID);
    this.router.navigate([this.user?.userUrl + "/jobs/", jobID]);
  }
  getRemoteID(id) {
    this.remote = this.remoteArray.find(test => test.id === id).description;
  }
  getEmploymentTypeID(id) {
    this.employmentTypeString = this.employmentType.find(t => t.id === id).description;
  }
  getJoDurationID(id) {
    this.jobDurationString = this.jobDurationArray.find(t => t.id === id).description;
  }

  getCountryID(id) {
    this.countryJsonObject = this.countryJsonArray.find(t => t.ISO_2 === id);
  }
  getCurrencyID(id) {
    this.currencyObject = this.countryJsonArray.find(t => t.currency.Alphabeticcode === id);
  }
  getCurrencyForUserSearch(id) {
    this.currencyUserSearchObject = this.countryJsonArray.find(t => t.ISO_2 === id);
  }

  getPaymentFormID(id) {
    this.paymentID = this.salaryArray.find(t => t.id === id).description;
  }

  goToMessages() {
    this.router.navigateByUrl("messages");
  }

  saveJob(jobID: string) {
    this.user.savedJobs.unshift({ jobId: jobID, timestamp: new Date() });
    this.userService.updateUser(this.user._id, this.user);
    this.savedID = this.job._id;

  }

  removeJobFromPublic() {
    this.job.public = {} as any;
    this.jobService.updateJob(this.job._id, this.job);
  }

  removeProjectFromPublic() {
    this.project.public = {} as any;
    const indexProject = this.projectObjs.findIndex(t => t.projectID === this.pageJobs.projectID);

    this.projectObjs[indexProject].public = {};
    this.projectService.updateProject(this.project._id, this.project);
    this.projectService.updateProjectList(this.projectList._id, this.projectList);
  }

  saveJobFromIgnored(jobID: string) {
    this.user.savedJobs.unshift({ jobId: jobID, timestamp: new Date() });
    this.unIgnoreJob.emit(this.user);
  }
  removeFromIgnored(event) {
    this.unIgnoreJob.emit(this.user);
  }
  ignoreProject() {
    this.unIgnoreProject.emit(this.user);
  }
  unIgnore(jobID) {
    const job = this.user.ignoredJobs.findIndex(t => t.jobId === jobID);
    this.user.ignoredJobs.splice(job, 1);
    this.isIgnored = this.job._id;
    this.ignoreArray.emit();
    this.userService.updateUser(this.user._id, this.user)

  }

  ignoreFromSave(jobID: string) {
    this.user.ignoredJobs.unshift({ jobId: jobID, timestamp: new Date() });
    const job = this.user.savedJobs.findIndex(t => t.jobId === jobID);
    this.user.savedJobs.splice(job, 1);
    this.userService.updateUser(this.user._id, this.user);
  }
  ignoreJob(jobID: string) {
    this.user.ignoredJobs.unshift({ jobId: jobID, timestamp: new Date() });
    this.userService.updateUser(this.user._id, this.user);
  }
  apply(job) {
    const jIssue: JIssue = {
      status: IssueStatus.Applied,
      message: {
        userID: this.authService.userID,
        description: this.jIssue.message.description
      },
      userID: this.authService.userID,
      createdAt: new Date(),
      id: Math.floor(Math.random() * 9).toString() + 1
    }
    // this.job.manage_.unshift(jIssue);
    // this.jobService.updateJob(this.job._id, this.job);

    this.router.navigateByUrl(this.router.url + "/public/" + job + "/apply");
  }

  applyForJob(job) {
    const jIssue: JIssue = {
      status: IssueStatus.Applied,
      message: {
        userID: this.authService.userID,
        description: this.jIssue.message.description,
      },
      userID: this.authService.userID,
      createdAt: new Date(),
      id: Math.floor(Math.random() * 9).toString() + 1
    }
    // this.job.manage_.unshift(jIssue);
    // this.jobService.updateJob(this.job._id, this.job);
    localStorage.setItem('jobID', job);

    this.router.navigateByUrl(this.router.url + "/public/" + job + "/apply");
  }
  manageJob(jobID: string) {
    this.senderService.dataManageProjectID({ ID: jobID, isProject: false });
    this.senderService.dataManageJobID({ ID: jobID });
    this.router.navigateByUrl(this.router.url + "/" + jobID + "/manage");
  }

  moreInfo(jobID) {
    this.openMoreInfo = true;
    this.subscription = this.jobService.getJobMoreInfo(jobID).subscribe((job: Job) => {
      this.moreJobInfo = job;
    });
  }
  lessInfo() {
    this.openMoreInfo = false;
  }

  publicJob() {

    if (!this.pageJobs) {
      localStorage.setItem('jobID', this.job._id);

      this.senderService.send({ data: this.user, id: "user" })

      this.router.navigate([this.router.url + '/' + this.job._id + "/public"]);
    } else {
      const pageID = localStorage.getItem("pageID");
      this.pageService.getPage(pageID).subscribe(t => {
        this.page = t.page;
        localStorage.setItem('jobID', this.job._id);
        this.senderService.send({ data: this.page, id: "page" })
        this.router.navigateByUrl(this.page.pageUrl + '/jobs/' + this.job._id + "/public");
      })

    }

  }

  searchJob(jobID) {
    if (this.pageJobs) {
      const pageID = localStorage.getItem("pageID");
      this.pageService.getPage(pageID).subscribe(t => {
        this.page = t.page;
        localStorage.setItem('searchJobId', jobID);
        localStorage.setItem("jobUrl", this.router.url + `/${jobID}`);
        this.router.navigateByUrl(this.page.pageUrl + '/jobs/' + jobID + "/search");
      });
    } else {
      localStorage.setItem("searchJobId", this.job._id);
      localStorage.setItem("jobUrl", this.router.url + `/${jobID}`);
      this.router.navigateByUrl(this.router.url + `/${jobID}` + "/search");

    }
  }

  searchObject(id) {
    if (!!this.job) {
      localStorage.setItem("searchJobId", this.job._id);
      localStorage.setItem("jobUrl", this.router.url + `/${id}`);
      this.router.navigateByUrl(this.router.url + `/${id}` + "/search");
    } else {
      localStorage.setItem("searchProjectID", id);
      localStorage.setItem("jobUrl", this.router.url + `/${id}`);
      this.router.navigateByUrl(this.user.userUrl + "/projects/" + id + "/search");
    }
  }

  searchProjectPage(id) {
    const pageID = localStorage.getItem("pageID");
    this.pageService.getPage(pageID).subscribe(t => {
      this.page = t.page;
      localStorage.setItem('searchProjectID', id);
      localStorage.setItem("projectUrl", this.router.url + `/${id}`);
      this.router.navigateByUrl(this.page.pageUrl + '/projects/' + id + "/search");
    })
  }


  public onClick(event: any): void {
    event.stopPropagation();
  }


  contact(jobId, jobID) {
    localStorage.setItem('userID', this.authService.userID);
    this.senderService.sendDataForApply(jobID);
    this.router.navigateByUrl('/jobs/public/' + this.authService.userID + "/apply");

    //this.router.navigateByUrl(this.router.url + `/${jobID}` + "/search");
  }

  public incomes(id, job) {
    if (id === Incomes.month) {
      this.jobSalary = job.incomes?.month.toFixed(0);
    } else if (id === Incomes.year) {
      this.jobSalary = job.incomes?.year.toFixed(0);
    } else if (id === Incomes.hour) {
      this.jobSalary = Math.trunc(job.incomes?.hour)
    }
  }


  unsaveJob(jobID) {
    const job = this.user.savedJobs.findIndex(t => t.jobId === jobID);
    this.user.savedJobs.splice(job, 1);
    this.savedID = this.job._id;
    this.userService.updateUser(this.user._id, this.user)
  }

  test2(id) {
    this.removeSavedProject.emit(this.user)
  }

  removeProjectPage(id) {
    this.removeSavedProjectPage.emit(id)
  }

  ignoreProjectPage(id) {
    this.addToIgnoreProjectPage.emit(id)
  }

  unignoreProjectPage(id) {
    this.removeFromIgnorePageProject.emit(id);
  }

  goToApplied() {
    this.router.navigateByUrl(this.user.userUrl + "/jobs/applied")
  }

  showManage() {
    if (this.job) {
      this.senderService.dataManageJobID({ ID: this.job._id, isJob: true });
      this.senderService.dataManageProjectID(null)
    } else {
      this.senderService.dataManageProjectID({ ID: this.project._id, isProject: true });
      this.senderService.dataManageJobID(null);
    }
  }

  test(t: any) {
    this.senderService.dataManageProjectID({ ID: this.project._id, isProject: true });
  }





  set savedID(id) {
    this.isSaved = this.user?.savedJobs.find(t => t.jobId === id);
  }
  get savedID() {
    return this.isSaved;
  }

  set ignoredID(id) {
    this.isIgnored = this.user?.ignoredJobs.find(t => t.jobId === id);
  }

  get ignoredID() {
    return this.isIgnored;
  }

  get myOject() {
    return this.project.userID === this.authService.userID;
  }


  cancel(job) {
    localStorage.setItem('userID', this.authService.userID);
    this.senderService.sendDataForApply(this.job);
    this.router.navigateByUrl("jobs/public/" + this.job._id + "/cancel");
  }
  cancelApply(job) {
    const dataForCancel = {
      status: 2,
      timestamp: new Date(),
      description: job.data.cancelMessage,
      cancelID: this.authService.userID
    }
    this.job.manage_.find(t => t.userID === this.authService.userID).cancel = dataForCancel;

    let index = this.user.appliedJobs.findIndex(t => t.jobId === job.data._id);
    this.user.appliedJobs.splice(index, 1);

    this.appliedID = this.job._id;
    this.userService.updateUser(this.user._id, this.user);
    this.jobService.updateJob(this.job._id, this.job);

    this.cdServ.emitChanges(true);

  }
  message(message) {
    this.router.navigateByUrl("messages");
  }


  applyJob(job) {

    let jIssue: JIssue = {
      status: IssueStatus.Applied,
      message: {
        userID: this.authService.userID,
        description: job.description,
      },
      userID: this.authService.userID,
      createdAt: new Date(),
      id: Math.floor(Math.random() * 9).toString() + 1,
      cancel: {
        status: Status.Accepted,
        timestamp: new Date()
      },
    }
    job.data.manage_.unshift(jIssue);
    this.userService.getMessageList(this.loggedUser.user._id).subscribe(response => {
      response[0].messages.unshift({ jobID: job.data._id, c: job.data.c })
      this.userService.updateMessageList(response[0]._id, response[0]);
    })

    //this.userService.updateUser(this.user._id, this.user);
    this.jobService.updateJob(job.data._id, job.data);
    this.appliedID = this.user._id;
  }

  applyProjectPage(project) {
    console.log(project, "project")
  }

  applyProject(project) {

    let jIssue: JIssue = {
      status: IssueStatus.Applied,
      message: {
        userID: this.authService.userID,
        description: project.description,
      },
      userID: this.authService.userID,
      createdAt: new Date(),
      id: Math.floor(Math.random() * 9).toString() + 1,
      cancel: {
        status: Status.Accepted,
        timestamp: new Date()
      },
    }
    project.data.manage_.unshift(jIssue);
    this.userService.getMessageList(this.loggedUser.user._id).subscribe(response => {
      response[0].messages.unshift({ projectID: project.data._id, c: project.data.c })
      this.userService.updateMessageList(response[0]._id, response[0]);
    })

    //this.userService.updateUser(this.user._id, this.user);
    this.projectService.updateProject(project.data._id, project.data);
    this.appliedID = this.user._id;
  }


  set appliedID(id: string) {
    if (!this.isPage) {
      if (!!this.job) {
        this.hasApplied = this.job?.manage_?.find(t => t.userID === id)?.userID === this.user?._id;
      } else {
        this.hasApplied = this.project?.manage_?.find(t => t.userID === id)?.userID === this.user?._id;

      }
    }

  }
  get appliedID() {
    return this.hasApplied;
  }

  set appliedIDPage(id: string) {
    if (this.project) {
      this.hasAppliedPage = this.project?.manage_?.find(t => t.pageID === this.selectedPageID)?.pageID === this.selectedPageID;
    } else {
      this.hasAppliedPage = this.job?.manage_?.find(t => t.pageID === this.selectedPageID)?.pageID === this.selectedPageID;

    }
    this.hasApplied = false;
  }
  get appliedIDPage() {
    return this.hasAppliedPage;
  }


  set appliedProject(id: string) {
    this.projectApplied = this.project?.manage_?.find(t => t.userID === id)?.userID === this.user?._id;
  }

  get appliedProject() {
    return this.projectApplied;
  }

  set appliedProjectPage(id: string) {
    this.projectAppliedPage = this.project?.manage_?.find(t => t.pageID === id)?.pageID === this.selectedPageID;
  }
  get appliedProjectPage() {
    return this.projectAppliedPage;
  }

  contactUser(data) {

    if (localStorage.getItem("isPageSearchJob")) {
      const id = localStorage.getItem("isPageSearchJob");
      this.pageService.getPage(id).subscribe((res: Page) => {
        let jIssue: JIssue = {
          status: IssueStatus.Applied,
          message: {
            userID: this.authService.userID,
            pageID: res._id,
            description: data.contactMessage
          },
          createdAt: new Date(),
          userID: data.data._id,
          pageID: res._id,
          cancel: {
            status: Status.Pending,
            timestamp: new Date(),
          },
          id: Math.floor(Math.random() * 9).toString() + 1,
        }

        data.data.messages.unshift({ jobID: this.dataJob._id, timestamp: new Date() })
        this.dataJob.manage_.unshift(jIssue);

        this.userService.updateUser(data.data._id, data.data);
        this.jobService.updateJob(this.dataJob._id, this.dataJob);


      })



      //  this.pageService.updatePage(this.pageData._id, this.pageData)
      //  this.projectService.updateProject(this.projectSearch._id, this.projectSearch)

    } else {
      let jIssue: JIssue = {
        status: IssueStatus.Applied,
        message: {
          userID: this.authService.userID,
          pageID: this.pageData._id,
          description: data.contactMessage
        },
        createdAt: new Date(),
        userID: this.authService.userID,
        pageID: this.pageData._id,
        cancel: {
          status: Status.Pending,
          timestamp: new Date(),
        },
        id: Math.floor(Math.random() * 9).toString() + 1,
      }
      this.pageData.messages.unshift({ projectID: this.projectSearch._id, timestamp: new Date() })
      this.projectSearch.manage_.unshift(jIssue);

      // this.pageService.updatePage(this.pageData._id, this.pageData)
      // this.projectService.updateProject(this.projectSearch._id, this.projectSearch)


    }

  }

  editTasksJob(data) {
    this.dialog
      .open(EditJobsDialogComponent, {
        data,
      })
      .afterClosed()
      .subscribe((res) => {
        this.job.skillsOffer = res.data.skillsOffer;
        this.jobService.updateJob(this.job._id, this.job);
      });
  }
  editQualiciations(data) {
    this.dialog
    .open(QualificationsDialogArrayComponent, {
      data,
    })
    .afterClosed()
    .subscribe((res) => {
    console.log(res, "res")
    //  this.jobService.updateJob(this.job._id, this.job);
    });
  }

  editPCKnowledge(data: EditDetailsData) {
    this.dialog
      .open(EditJobsDialogComponent, {
        data,
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          if (res.isProject) {
            this.project.pcKnowledge = res.data.pcKnowledge;
            this.projectService.updateProject(this.project._id, this.project);
          } else {
            this.job.pcKnowledge = res.data.pcKnowledge;
            this.jobService.updateJob(this.job._id, this.job);
          }
        }
      });
  }

  editLanguage(data) {
    this.dialog.open(LanguageDialogComponent, {
      data,
    }).afterClosed().subscribe((res) => {
      if (!res) {
        return;
      } else {
        if (res.isProject) {
          this.project.language = res.project.language;
          this.projectService.updateProject(this.project._id, this.project);
        }

        this.job.language = res.job.language;
        this.jobService.updateJob(this.job._id, this.job)
      }
    })
  }

  editDriveLicense(data) {
    this.dialog.open(DriveLicenseDialogComponent, {
      data
    }).afterClosed().subscribe((res) => {
      if (!res) {
        return;
      } else {
        if (res.isProject) {
          this.project.driveLicense = res.project.driveLicense;
          this.projectService.updateProject(this.project._id, this.project);
        } else {
          this.job.driveLicense = res.driveLicense;
          this.jobService.updateJob(this.job._id, this.job);

        }
      }
    })
  }

  editSkillsOffer(data: EditDetailsData) {
    this.dialog
      .open(EditJobsDialogComponent, {
        data,
      })
      .afterClosed()
      .subscribe((res) => {
        console.log(res, "res")
        if (res.isProject) {
          if (res.data) {
            this.project.skillsOffer = res.data.skillsOffer;
          } else {
            this.project.skillsOffer = res.project.skillsOffer;
          }

          //   this.data.skillsOffer = res.skillsOffer;
          this.projectService.updateProject(this.project._id, this.project);
        }
      });
  }

  deleteProjectFreelancer() {
    this.project.freelancer = false;
    // this.projectService.updateProject(this.data._id, this.data)
  }



  editDescription(data) {
    this.dialog
      .open(EditJobsDialogComponent, {
        data,
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          if (res.isProject) {
            this.project.description = res.data.description;
            this.projectService.updateProject(this.project._id, this.project);
          } else {
            this.job.description = res.data.description;
            this.jobService.updateJob(this.job._id, this.job);
          }
        }
      });
  }
  publicProject() {
    if (!this.pageJobs) {
      localStorage.setItem('projectID', this.project._id);
      this.senderService.send({ data: this.user, id: "user" })
      this.router.navigate([this.router.url + '/' + this.project._id + "/public"]);
    } else {
      const pageID = localStorage.getItem("pageID");
      this.pageService.getPage(pageID).subscribe(t => {
        this.page = t.page;
        localStorage.setItem('projectID', this.project._id);
        this.senderService.send({ data: this.page, id: "page" })
        this.router.navigateByUrl(this.page.pageUrl + '/projects/' + this.project._id + "/public");
      })
    }
  }
  searchProject() {

  }





}
