import { Component, Inject, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IssueType, JIssue } from 'src/app/shared/interfaces/jira.model';
import { Job, Project } from 'src/app/shared/interfaces/model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { JobsService } from 'src/app/shared/services/jobs.service';
import { ProjectService } from 'src/app/shared/services/project.service';

@Component({
  selector: 'app-issue-modal',
  templateUrl: './issue-modal.component.html',
  styleUrls: ['./issue-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class IssueModalComponent implements OnInit, OnChanges {

  constructor(@Inject(MAT_DIALOG_DATA) public data: Issue,
    private projectService: ProjectService,
    private jobService: JobsService,
    private authService: AuthService) { }
  ngOnChanges(changes: SimpleChanges): void {

  }

  ngOnInit(): void {

    if (this.data.issue.seen &&  (!this.data.isPage || this.data.isPage === undefined)) {
      const checkData = this.data.issue.seen.find(t => t.userID === this.authService.userID &&  typeof t.pageID === 'undefined')?.userID === this.authService.userID;
      if (checkData){
      } else {

          this.data.issue.seen.unshift({
            userID: this.authService.userID,
            timestamp: new Date(),

          })
          if (this.data.isProject) {
            const issueIndex = this.data.project?.manage_.findIndex(t => t.userID === this.data.issue.userID);
            this.data.project.manage_[issueIndex].seen = this.data.issue.seen;

            this.projectService.updateProject(this.data.project._id, this.data.project);
          } else {
            const issueIndex = this.data.job?.manage_.findIndex(t => t.userID === this.data.issue.userID);
            this.data.job.manage_[issueIndex].seen = this.data.issue.seen;


            this.jobService.updateJob(this.data.job._id, this.data.job);

          }


      }
    } else {
      const checkData = this.data.issue.seen.find(t => t.userID === this.authService.userID && t.pageID === this.data.isPage);

      if (!!checkData) {
        if (checkData.pageID === this.data.isPage && checkData.userID === this.authService.userID) {
        }
      }
      else {
        this.data.issue.seen.unshift({
          userID: this.authService.userID,
          pageID: this.data.isPage,
          timestamp: new Date()
        })
        const issueIndex = this.data.project.manage_.findIndex(t => t.userID === this.data.issue.userID);
        this.data.project.manage_[issueIndex].seen = this.data.issue.seen;
        this.projectService.updateProject(this.data.project._id, this.data.project);
      }

    }
  }

}

export interface Issue {
  issue: JIssue,
  job: Job,
  project?: Project,
  isPage?: any
  isProject?: boolean
}
